// src/pages/[...].js
import React from "react";
import { addTraillingSlash, algogenerateFilters, generateFilters, parseResultsUrl } from "@lib/searchUtils";
import SearchResults from "@templates/new-projects-search-template";
import algoliasearch from "algoliasearch/lite";

export async function getServerData(context) {
  const { params } = context; // Extract dynamic params from context
  const path = "/new-projects/" + params["*"]; // Access the wildcard param
  const locationObject = {
    pathname: addTraillingSlash(path) || '', // Ensure it's a string or default to an empty string
  };




  if (path.startsWith("/new-projects")) {
    const pageUrlData = parseResultsUrl(locationObject)


    const API_URL = `${process.env.PROPERTY_API_URL}/api/search`;
    const AUTH_TOKEN = process.env.PROPERTY_API_TOKEN;
    try {

      if (pageUrlData?.layoutVal === "map") {
        const filterQuery = algogenerateFilters({ ...pageUrlData })
        const index = algoliasearch("D4VBAN0OOQ", process.env.GATSBY_ALGOLIA_SEARCH_API_KEY).initIndex(pageUrlData.indexVal);
        const searchData = await index.search("", { filters: `${filterQuery}`, page: 0, hitsPerPage: 50 });
        return {
          props: {
            data: searchData, // Returning hits array
            template: "search"
          }
        };
      }
      const headers = {
        "Accept-Encoding": "gzip, deflate",
        "Content-Type": "application/json",
        Authorization: AUTH_TOKEN,
      };
      // Fetch data for Search template
      const sortQuery = (() => {
        switch (pageUrlData?.sortVal) {
          case "prod_new_developments_price_desc": return { filter_price: "desc" };
          case "prod_new_developments_price_asc": return { filter_price: "asc" };
          default: return { "`rank`": "asc", updated_at: "desc" };
        }
      })();
      const filterQuery = generateFilters({ ...pageUrlData })
      const body = {
        content_type: "off_plan",
        algolia_data: true,
        filters: filterQuery,
        sort: sortQuery,
        page: pageUrlData?.pageVal || 0,
        limit: "10",
        content: {
          component: "offplan_copy",
          area: pageUrlData?.areaVal ? pageUrlData?.areaVal : ["all"],
          building_type: pageUrlData?.buildingVal ? pageUrlData?.buildingVal : "",
          department: pageUrlData?.departmentVal
        }
      };

      const res = await fetch(API_URL, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });
      const data = await res.json();
      return { props: { data, template: "search" } };
    } catch (error) {
      console.error("API Fetch Error:", error);
    }
  }

  return { props: { data: null, template: null } };
}

const DynamicPage = ({ serverData, location }) => {
  if (serverData.template === "search") {
    return <SearchResults serverData={serverData?.data} location={location} />;
  } else {
    return <div>404 - Page Not Found</div>;
  }
};

export default DynamicPage;
