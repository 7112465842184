// import ContactForm from "@components/forms/ContactForm"
import MailIcon from "@components/icons/MailIcon"
import PhoneIcon from "@components/icons/PhoneIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { getWhatsAppURL, gtagReportConversion } from "@lib/utils"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./ContactFomModule.scss"
import loadable from "@loadable/component"
import useInteractive from "@hooks/useInteractive"

const ContactForm = loadable(() => import("@components/forms/ContactForm"))

const ContactFomModule = ({ module, className, id, masterplan_image}) => {
  const { isTablet } = useDeviceMedia()
  const { hasInteracted } = useInteractive()

  const { email, phone, whatsapp } = useBrandInfo()

  if (!module) return null

  const { heading, title } = module

  const CtaSection = (
    <div className="cta-section d-none d-xl-block">
      <div className="cta-item">
        <div className="cta-icon">
          <WhatsAppIcon />
        </div>
        <div className="cta-content">
          <p className="cta-label">WhatsApp</p>
          <a
            className="cta-value"
            // href={getWhatsAppURL(whatsapp)}
            target="_blank"
            rel="noreferrer"
            id="openbot"
          >
            Click to WhatsApp
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="cta-item">
        <div className="cta-icon">
          <PhoneIcon />
        </div>
        <div className="cta-content phone-content">
          <p className="cta-label">Phone</p>
          <a className="cta-value" href={`tel:${phone}`} onClick={() => gtagReportConversion(`tel:${phone}`)}>
            {phone}
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="cta-item">
        <div className="cta-icon">
          <MailIcon />
        </div>
        <div className="cta-content">
          <p className="cta-label">Email</p>
          <a
            className="cta-value"
            href={`mailto:${email}`}
            target="_blank"
            rel="noreferrer"
          >
            {email}
          </a>
        </div>
      </div>
    </div>
  )

  return (
    <div
      className={clsx("contact-form-wrapper ", "section-p", className, masterplan_image?.url && "blue")}
      id={id}
    >
      <Container className={clsx("contact-form-container ")}>
        <div className="content-section">
          <h3 className="title">{heading}</h3>
          <div className="description">
            <p className="">{title}</p>
          </div>
          <div className="cta-section mob-view-whasapp d-block d-xl-none">
            <div className="cta-item">
              <div className="cta-icon">
                <WhatsAppIcon />
              </div>
              <div className="cta-content">
                {/* <p className="cta-label">WhatsApp</p> */}
                <a
                  className="cta-value"
                  // href={getWhatsAppURL(whatsapp)}
                  target="_blank"
                  rel="noreferrer"
                  id="openbot"
                >
                  Click to WhatsApp
                </a>
              </div>
            </div>
          </div>
          {!isTablet && CtaSection}
        </div>
        <div className="form-section">{hasInteracted && <ContactForm />}</div>
        {isTablet && CtaSection}
      </Container>
    </div>
  )
}

export default ContactFomModule
